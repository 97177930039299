export interface IStatusOrderList {
  id: number;
  label: string;
  value: string;
  count?: number;
}

export enum ORDER_PAYMENT_CHANNEL {
	ALL = '',
	COD = 'COD',
	TRANSFER = 'TRANSFER'
}

export enum ORDER_STATUS {
	DRAFT = 'DRAFT',
	PROCESS = 'PROCESS',
	PREPARE_SEND = 'PREPARE_SEND',
	SENT = 'SENT',
	COMPLETED = 'COMPLETED',
	REJECT = 'REJECT',
	CANCEL = 'CANCEL',
}

export enum ADJUST_STATUS_ORDER {
	COD_CALENDAR = 'COD_CALENDAR',
	COD_FREEZE = 'COD_FREEZE',
  }

export type OrderStatusChannelType = {
	[key in ORDER_STATUS | ADJUST_STATUS_ORDER]: {
		text: string;
		color: string;
	}
}

export const ORDER_STATUS_CHANNEL: OrderStatusChannelType = {
	DRAFT: {
		text: 'ร่าง',
		color: 'info'
	},
	PROCESS: {
		text: 'คำสั่งซื้อรอดำเนินการ',
		color: '',
	},
	PREPARE_SEND: {
		text: 'เตรียมตัวจัดส่ง',
		color: 'warning'
	},
	SENT: {
		text: 'อยู่ระหว่างจัดส่ง',
		color: ''
	},
	COMPLETED: {
		text: 'จัดส่งสำเร็จ',
		color: 'success'
	},
	REJECT: {
		text: 'ตีกลับ',
		color: 'danger'
	},
	CANCEL: {
		text: 'ถูกยกเลิก',
		color: 'danger'
	},
	COD_FREEZE: {
		text: 'อายัด',
		color: 'danger'
	},
	COD_CALENDAR: {
		text: 'แก้ไขปฏิทิน COD',
		color: 'danger'
	}
}