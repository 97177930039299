import { COURIER, courierMap } from '@/constants/courier'

export const mapCourierLinkTracking = (courier: COURIER, trackingNumber: string): string => {
	const mapCourier = courierMap[courier]


	return mapCourier?.generateLinkTrackingNumber(trackingNumber) as string
}
export const mapRejectTrackingRef = (courier: COURIER, order: {trackingNumber:string,rejectTrackingNumber:string}): string => {
	let rejectTrackingNumber = order?.rejectTrackingNumber
	if (courier === COURIER.SPX || courier === COURIER.EMS) {
		rejectTrackingNumber = order.trackingNumber
	}
	return rejectTrackingNumber
}

interface CourierInfo {
  id: string,
  name: string,
  image: string,
  linkUrl: string
}


const courierInfo = [
	{
		id: '62d8d8b3d119d34431f0324b',
		name: 'ไปรษณีย์ไทย',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_thaipost.jpeg',
		linkUrl: 'https://track.thailandpost.co.th/?trackNumber=',
	},
	{
		id: '616a5b887d33520401450da3',
		name: 'J&T Express',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_jt.jpeg',
		linkUrl: 'https://www.jtexpress.co.th/service/track?waybillNo=',
	},
	{
		id: '616a5b5a7d33520401450d9f',
		name: 'Flash',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_flash.jpeg',
		linkUrl: 'https://www.flashexpress.co.th/tracking?se=',
	},
	{
		id: '651126ea1835ad1bf3b2eb04',
		name: 'Flash Bulky',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/flash-bulky.png',
		linkUrl: 'https://www.flashexpress.co.th/tracking?se=',
	},
	{
		id: '616a5b747d33520401450da1',
		name: 'Kerry',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_kerry.jpeg',
		linkUrl: 'https://th.kerryexpress.com/th/track/v2/?track=',
	},
	{
		id: '657834199717e74e6991233b',
		name: 'Shopee',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_shopee.jpeg',
		linkUrl: 'https://spx.co.th/track?',
	},
	{
		id: '665e8cd79e801480b5eeb0e6',
		name: 'Dhl',
		image: 'https://storage.googleapis.com/livezer-dev.appspot.com/courier/ic_dhl.png',
		linkUrl: 'https://ecommerceportal.dhl.com/track/?ref=',
	},
]

export const getCourierInfo = (courierId: string): CourierInfo | any => {
	return courierInfo.find(courier => courier.id === courierId)
}